const $ = jQuery.noConflict();
const mainHeader = document.querySelector('.main-header');


function toggleMobileMenu() {
    const hamburger = document.querySelector('.hamburger');
    const menu = document.querySelector('.menu-header-menu-container');
    const menuClose = document.querySelector('.menu-close');

    hamburger.addEventListener('click', ev => {
        ev.preventDefault()
        menu.classList.add('visible');
    });

    menuClose.addEventListener('click', ev => {
        ev.preventDefault()
        menu.classList.remove('visible');
    });
}

function subMenus() {
    const menuItemsParent = document.querySelectorAll('.menu-item-has-children > a');


    menuItemsParent.forEach((menuItem) => {
        menuItem.addEventListener('click', (ev) => {
            ev.preventDefault();
            const subMenu = menuItem.nextElementSibling;
            const $subMenu = $(subMenu);
            $subMenu.slideToggle();
        })
    })

}

function stickyMenu() {
    const wpAdminBar = document.querySelector('#wpadminbar');
    const headerHeight = mainHeader.offsetHeight;
    const spacer = document.createElement('div');
    spacer.classList.add('header-spacer');
    spacer.style.height = headerHeight + 'px';
    document.querySelector('.header-main-footer-wrap').insertBefore(spacer, mainHeader);
    if(wpAdminBar) {
        const heightAdminBar = wpAdminBar.offsetHeight;
        mainHeader.style.top = heightAdminBar +'px';
        // document.body.style.paddingTop = heightAdminBar +'px';
    }
}





export default function () {
    toggleMobileMenu();
    subMenus();
    stickyMenu()


}