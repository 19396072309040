const $ = jQuery;

export default function () {
    $('.hero-section .hero-slider').slick({
        autoplay: true,
        autoplaySpeed: 4500,
        infinite: true,
        fade: true,
        speed: 1100,
        pauseOnHover: false,
        pauseOnFocus: false
        // cssEase: 'ease-out'
    });
};